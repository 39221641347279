<template>
  <Dialog
    v-model:visible="this.visible"
    modal
    header="Overzicht"
    :style="{ width: '50vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <template
      v-for="(dates, index) in locatieOverzicht.meals"
      :key="dates.date"
    >
      <Divider v-if="dates.date === dateClicked" />
      <div
        class="font-m font-bold"
        style="margin-top: 20px"
        v-if="dates.date === dateClicked"
      >
        Loactienaam:
        <div>
          <i class="fa-solid fa-house fa-xs"></i>
          {{ locationName }}
        </div>
        <p style="margin-top: 20px" class="font-m font-bold">Datum:</p>
        <div>
          <i class="fa-regular fa-calendar fa-xs"></i>
          {{ weekday[index].day }} - {{ dateTime(dates.date) }}
        </div>
        <p style="margin-top: 20px" class="font-m font-bold">
          Totaal aantal aanmeldingen:
        </p>
        <p>{{ dates.registrations.length }}</p>
        <p style="margin-top: 20px" class="font-m font-bold">Wat eten we:</p>
        <div class="text-900 font-m">
          <span
            class="font-s text-color-gray"
            v-if="dates.memo === '' || dates.memo === null"
          >
            Er is nog geen maaltijd ingevoerd</span
          >
          {{ dates.memo }}
        </div>
        <p style="margin-top: 20px" class="font-m font-bold">Aanmeldingen:</p>
        <div
          class="text-900 font-m capitalize"
          v-for="users in dates.registrations"
        >
          <span style="text-align: center">
            <div style="float: left; clear: left; text-align: left">
              <i
                class="fa-solid fa-user fa-xs"
                :class="{ colorBlue: users.id == registeredUser.id }"
              ></i>
              {{ users.name }}
              <span v-if="users.id == registeredUser.id">(jij)</span>
              <span
                style="margin-left: 5px"
                class="text-color-gray not-capitalize"
                v-if="users.memo"
              >
                -
                <i class="fa-regular fa-comment fa-sm"></i>
                {{ users.memo }}</span
              >
            </div>
          </span>
        </div>
      </div>
      <div
        v-if="!dates.registrations[0] && dates.date === dateClicked"
        class="text-color-gray font-s"
      >
        Er zijn nog geen aanmeldingen voor deze dag!
      </div>
    </template>
  </Dialog>
  <div
    class="surface-card p-4 user-select-n mb-3 card-border"
    style="border-radius: 2rem; flex: 1"
  >
    <div class="justify-content-between">
      <PersonList
        @refresh="refreshData1()"
        v-bind:dates="dates"
        v-bind:index="index"
        v-bind:weekday="weekday"
        v-bind:locationID="locationID"
        v-bind:users="dates.registrations"
        v-bind:formattedToday="formattedToday"
      />
    </div>
    <div class="text-block1 mt-3">
      <Divider class="divider-class" />
      <div class="mt-3 justify-content-between">
        <div :class="{ 'mb-3': showMenu[index] }">
          <div style="width: 100%">
            <span
              class="font-m"
              :class="{ dateToday: formattedToday === dates.date }"
              >Wat eten we:</span
            >
          </div>

          <div class="font-m" style="align-items: center">
            <span class="mb-1 mt-1 flex add-note" style="position: relative">
              <span
                class="text-color-light-gray not-capitalize mr-1"
                v-if="!dates.memo"
              >
                <i class="pi pi-plus-circle" style="line-height: 25px"></i>
              </span>
              <!-- <span
                :id="`Menu-${locationID}-${index}`"
                style="
                  visibility: hidden;
                  position: absolute;
                  left: 0;
                  right: 0;
                "
                >{{ menu }}</span
              > -->
              <Textarea
                :id="`Menu-${locationID}-${index}`"
                v-on:keydown.enter.exact.prevent="
                  inputDebounceEnter(dates.date, menu)
                "
                v-on:blur="inputDebounceEnter(dates.date, menu)"
                class="add-note-input"
                v-model="menu"
                placeholder="Maaltijd toevoegen"
                autoResize
                :spellcheck="false"
                rows="1"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Divider
    :class="{ 'hiden-divider': isHidden }"
    v-if="index !== 6 && index !== 13 && index !== 20"
  />
</template>

<script>
import NoteField from "./NoteField.vue";
import MenuField from "./MenuField.vue";
import { useDebounceFn } from "@vueuse/core";
import PersonList from "./PersonList.vue";
import moment from "moment";
import { serverURL, baseURL } from "@/fetch";
import keycloak from "@/main";
import { ref } from "vue";

const visible = ref(false);
const dateClicked = ref();

export default {
  name: "DayOverview",
  components: { NoteField, PersonList, MenuField },
  emits: ["refresh"],
  props: [
    "locationID",
    "weekIndex",
    "formattedToday",
    "locationName",
    "dates",
    "index",
    "locatieOverzicht",
    "registeredUser",
    "show",
    "tabClicked",
  ],
  inject: ["keycloak"],
  mounted() {
    this.addOpmerkingen();
    if (
      this.index === 4 ||
      this.index === 5 ||
      this.index === 11 ||
      this.index === 12 ||
      this.index === 18 ||
      this.index === 19
    ) {
      this.isHidden = true;
    }
  },
  watch: {
    dates: function () {
      this.addOpmerkingen();
    },
    tabClicked: function () {
      this.$nextTick(() => {
        this.adjustHeight();
      });
    },
  },
  methods: {
    async refreshData1() {
      this.$emit("refresh");
    },
    addOpmerkingen() {
      const menu1 = JSON.parse(JSON.stringify(this.dates));
      if (menu1) {
        if (menu1.memo !== null) {
          this.menu = menu1.memo;
        }
      }
    },
    debouncedFn: useDebounceFn(function (dates, menu) {
      this.addMenu(dates, menu);
    }, 1000),
    inputDebounce(dates, menu) {
      this.addMenu(dates, menu);
    },
    inputDebounceEnter(dates, menu) {
      document
        .getElementById("Menu-" + this.locationID + "-" + [this.index])
        .blur();
      this.debouncedFn(dates, menu);
    },
    adjustHeight() {
      const textvak = document.getElementById(
        "Menu-" + this.locationID + "-" + [this.index]
      );
      textvak.style.height = "auto"; // Reset de hoogte
      textvak.style.height = `${textvak.scrollHeight}px`; // Stel de hoogte in op basis van de inhoud
    },
    async addMenu(date, menu) {
      let self = this;

      const seccondURL = self.locationID;
      await fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/change-memo-for-meal`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            date: date,
            mealType: "DINNER",
            memo: menu,
          }),
        }
      )
        .then(
          () =>
            document
              .getElementById("Menu-" + this.locationID + "-" + [this.index])
              .blur(),
          this.$toast.add({
            severity: "success",
            summary: "opgeslagen!",
            detail: "De door jou ingevulde opmerking is opgeslagen!",
            life: 3000,
          })
        )
        .then(() => this.refreshData1());
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
  data() {
    return {
      isHidden: false,
      visible: false,
      dateClicked: {},
      menu: [],
      weekday: [
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
      ],
      showMenu: [null],
    };
  },
};
</script>

<style scoped>
.mh-130 {
  position: relative;
}

.divider-class {
  margin: 0 !important;
}

.add-note-input {
  border: none;
  width: 100%;
  padding: 0 !important;
  cursor: pointer;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: var(--ejtblue) !important;
}

.p-inputtext:enabled:focus::placeholder {
  color: transparent;
}

.p-inputtext::placeholder {
  color: var(--gray-400);
}
</style>

<!--           
  v-if="weekIndex == 1 && index >= 7 && index <= 13" 
  v-if="weekIndex == 2 && index >= 14 && index <= 20"
-->
