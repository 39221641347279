<template>
  <ConfirmDialog class="ConfirmDialog" />
  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback, rejectCallback, icon }">
      <div
        class="flex flex-column align-items-center p-5 surface-overlay border-round min-widt-dialog"
      >
        <div
          style="overflow: hidden"
          class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8"
        >
          <i class="pi pi-sign-out text-5xl"></i>
          <!-- <img
            style="height: auto; width: 170%"
            src="../../assets/despicable-me-agnes.gif"
          /> -->
          <!-- <img style="height: auto; width: 150%" :src="message.image" /> -->
        </div>
        <span class="font-bold text-2xl block mb-2 mt-4">{{
          message.header
        }}</span>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex align-items-center gap-2 mt-4">
          <Button
            label="Nee"
            outlined
            @click="rejectCallback"
            class="w-8rem"
          ></Button>
          <Button label="Ja" @click="acceptCallback" class="w-8rem"></Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>

  <div
    id="navbar"
    class="colorBlack z-in-21"
    v-if="registeredUser.registered === true"
  >
    <div class="colorBlack z-in-21">
      <div class="height44px">
        <nav class="flex mx-auto px-51">
          <ul class="flex flex-grow space-x-4">
            <li>
              <a
                href="/"
                class="flex items-center space-x-3 md:mr-5 height100c navDecoration"
              >
                <span
                  class="text-gray-200 hover:text-gray-300 text-2xl font-semibold py-1 z-30 user-select-n"
                  >Eetjethuis<span
                    style="color: var(--ejtblue); font-weight: bold"
                    >.nl</span
                  ></span
                >
              </a>
            </li>
          </ul>
          <ul class="flex items-center textColorWhite space-x-5" id="navbar1">
            <div>
              <li
                @click="toggle"
                class="flex margin-left-0 user-select-n"
                id="navbar2"
                title="Instellingen"
                style="cursor: pointer"
              >
                <Avatar
                  class="avatar-border"
                  icon="pi pi-user"
                  shape="circle"
                  style="color: var(--ejtblack)"
                  ><img v-for="photo in photos" :src="photo" />
                </Avatar>
              </li>
              <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
                <template #start>
                  <span
                    style="width: 100%"
                    class="inline-flex align-items-center gap-1 px-2 py-2"
                  >
                    <span
                      style="width: 100%; text-align: center"
                      class="font-medium text-xl font-semibold"
                      >Eetjethuis<span
                        style="color: var(--ejtblue); font-weight: bold"
                        >.nl</span
                      ></span
                    >
                  </span>
                </template>
                <template #submenuheader="{ item }">
                  <span class="text-primary font-bold">{{ item.label }}</span>
                </template>
                <template #item="{ item }" class="surface-200">
                  <router-link
                    v-if="item.route"
                    v-slot="{ href, navigate }"
                    :to="item.route"
                  >
                    <a :href="href" @click="navigate">
                      <span :class="item.icon" />
                      <span class="ml-2">{{ item.label }}</span>
                    </a>
                  </router-link>
                  <a
                    v-if="item.label == 'Uitloggen'"
                    @click="confirmLogOut()"
                    style="cursor: pointer"
                  >
                    <span :class="item.icon" />
                    <span class="ml-2">{{ item.label }}</span>
                  </a>
                </template>
                <template #end>
                  <div
                    class="relative overflow-hidden w-full flex align-items-center p-2 pl-3 text-color border-noround"
                  >
                    <Avatar
                      class="mr-2 avatar-border"
                      icon="pi pi-user"
                      shape="circle"
                      style="color: var(--ejtblack)"
                      ><img v-for="photo in photos" :src="photo" />
                    </Avatar>
                    <span class="inline-flex flex-column">
                      <span class="font-bold capitalize"
                        >{{ registeredUser.name }} (Jij)</span
                      >
                    </span>
                  </div>
                </template>
              </Menu>
            </div>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <!-- <div class="colorBlack z-in-21" v-else>
    <navbarContentNotRegistered />
  </div> -->
</template>

<script>
import keycloak from "@/main";
import { fetchData, websiteURL } from "@/fetch";
export default {
  name: "NavBar",
  mounted() {
    this.registeredUser = fetchData;
    this.addPhoto();
    this.test(fetchData.value);
  },
  methods: {
    addPhoto() {
      if (this.registeredUser.photos) {
        this.photos.push(this.registeredUser.photos);
      }
    },
    confirmLogOut() {
      this.$confirm.require({
        message: "Weet u zeker dat u wilt uitloggen?",
        header: "Uitloggen",
        // icon: "pi pi-sign-out",
        // image: "http://develop.eetjethuis.nl/app/img/despicable-me-agnes.gif",
        accept: () => {
          keycloak.logout({ redirectUri: websiteURL });
        },
        reject: () => {},
      });
    },
    test(fetchData) {
      if (!fetchData.locations.length) {
        this.items = [
          {
            label: "App navigatie",
            items: [
              {
                label: "Dashboard",
                icon: "pi pi-th-large",
                route: "/",
              },
              {
                label: "Aanmelden",
                icon: "pi pi-list",
                route: "/aanmelden",
                disabled: "true",
              },
              {
                label: "Locaties",
                icon: "fas fa-location-dot",
                route: "/locationoverview",
              },
            ],
          },
          {
            label: "Instellingen",
            items: [
              // {
              //   label: "App instellingen",
              //   icon: "pi pi-cog",
              //   route: "/settings",
              // },
              {
                label: "Profiel aanpassen",
                icon: "pi pi-user",
                route: "/profiel",
              },
            ],
          },
          // {
          //   label: "Naar de homepagina",
          //   items: [
          //     {
          //       label: "Eetjethuis.nl",
          //       icon: "fa fa-arrow-up-right-from-square",
          //       route: "/",
          //     },
          //   ],
          // },
          {
            label: "Uitloggen",
            items: [
              {
                label: "Uitloggen",
                icon: "pi pi-sign-out",
              },
            ],
          },
        ];
      } else {
        this.items = [
          {
            label: "App navigatie",
            items: [
              {
                label: "Dashboard",
                icon: "pi pi-th-large",
                route: "/",
              },
              {
                label: "Aanmelden",
                icon: "pi pi-list",
                route: "/aanmelden",
              },
              {
                label: "Locaties",
                icon: "fas fa-location-dot",
                route: "/locationoverview",
              },
            ],
          },
          {
            label: "Instellingen",
            items: [
              // {
              //   label: "App instellingen",
              //   icon: "pi pi-cog",
              //   route: "/settings",
              // },
              {
                label: "Profiel aanpassen",
                icon: "pi pi-user",
                route: "/profiel",
              },
            ],
          },
          // {
          //   label: "Naar de homepagina",
          //   items: [
          //     {
          //       label: "Eetjethuis.nl",
          //       icon: "fa fa-arrow-up-right-from-square",
          //       route: "/",
          //     },
          //   ],
          // },
          {
            label: "Uitloggen",
            items: [
              {
                label: "Uitloggen",
                icon: "pi pi-sign-out",
              },
            ],
          },
        ];
      }
    },
  },
  data() {
    return {
      showMenu: false,
      registeredUser: {},
      photos: [],
      items: [],
    };
  },
};
</script>

<script setup>
import { ref } from "vue";
const menu = ref();

const toggle = (event) => {
  menu.value.toggle(event);
};

// const photos = ["http://documenten.scottpols.nl/ff.jpeg"];
// const photos = ["https://scottpols.nl/assets/img/Scott1.jpg"];
// const photos = [
//   "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
// ];
</script>

<style scoped>
.mt-16 {
  margin-top: 44px !important;
}

.z-30 {
  position: relative;
}

.py-4 {
  padding-top: 0.75rem !important ;
  padding-bottom: 0.75rem !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.padding-nav {
  padding: 10px 25px 10px 25px;
}

.px-51 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.top-2-px {
  top: 2px;
}

#navbar {
  transition: 0.2s;
}

.p-avatar-text {
  font-weight: 900;
}

.p-avatar {
  /* display: inline-block; */
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--avatarcolor);
  width: 32px;
  height: 32px;
}
.p-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-avatar {
  overflow: hidden;
}

.mr-2 {
  margin-right: 25px !important;
}
</style>

<style>
.surface-200 {
  color: #495057;
  background: #e9ecef !important;
}

#overlay_menu {
  display: block !important;
  position: fixed !important;
  top: 44px !important;
  right: 0;
  width: 250px;
}
.p-menuitem {
  margin: 0;
  padding: 0.75rem 1.25rem;
  background: #ffffff;
  font-weight: 700;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-menuitem-content {
  background: transparent !important;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.min-widt-dialog {
  min-width: 30rem;
}

@media (max-width: 480px) {
  .min-widt-dialog {
    min-width: 100vw;
  }
}
</style>
