<template>
  <div class="Dashboard">
    <RouterLink
      v-if="
        registeredUser.registered === true && registeredUser.locations.length
      "
      to="Aanmelden"
      style="text-decoration: none; color: inherit"
    >
      <div class="surface-card border-round div1 pointer">
        <div
          class="card-border border-round content-center surface-border heigt150"
        >
          <div class="dashboardFilled">
            <h1 class="font-l dashboard-buttons-content user-select-n">
              <i class="fa-solid fa-list"></i> Aanmelden
            </h1>
          </div>
        </div>
      </div></RouterLink
    >
    <div
      v-if="
        registeredUser.registered === true && !registeredUser.locations.length
      "
      style="text-decoration: none; color: inherit"
    >
      <div
        class="surface-card border-round div1"
        style="background-color: lightgray !important"
      >
        <div
          class="card-border border-round content-center surface-border heigt150"
        >
          <div class="dashboardFilled">
            <h1
              class="font-l dashboard-buttons-content user-select-n"
              style="color: gray"
            >
              <i class="fa-solid fa-list"></i> Aanmelden
            </h1>
          </div>
        </div>
      </div>
    </div>
    <RouterLink
      :disabled="true"
      to="LocationOverview"
      style="text-decoration: none; color: inherit"
    >
      <div class="surface-card border-round div2 pointer">
        <div
          class="card-border border-round content-center surface-border heigt150"
          style="margin: 0 !important"
        >
          <div class="dashboardFilled">
            <h1 class="font-l dashboard-buttons-content user-select-n">
              <i class="fa-solid fa-location-dot"></i> Locatieoverzicht
            </h1>
          </div>
        </div>
      </div></RouterLink
    >
    <RouterLink to="Profiel" style="text-decoration: none; color: inherit">
      <div class="surface-card border-round div3 pointer">
        <div
          class="card-border border-round content-center surface-border heigt150"
        >
          <div class="dashboardFilled">
            <h1 class="font-l dashboard-buttons-content user-select-n">
              <i class="fas fa-user-circle"></i> Profiel
            </h1>
          </div>
        </div>
      </div></RouterLink
    >
    <div @click="confirm()" class="surface-card border-round div4 pointer">
      <div
        class="card-border border-round content-center surface-border heigt150"
      >
        <div class="dashboardFilled">
          <h1 class="font-l dashboard-buttons-content user-select-n">
            <i class="fa-solid fa-right-from-bracket"></i> Uitloggen
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import keycloak from "@/main";
import { websiteURL, fetchData } from "@/fetch";

export default {
  name: "Dashboard",
  mounted() {
    this.loadLocationData();
  },
  methods: {
    async loadLocationData() {
      this.registeredUser = fetchData;
    },
    confirm() {
      this.$confirm.require({
        header: "Weet u zeker dat u wilt uitloggen?",
        acceptClass: "p-button-danger",
        accept: () => {
          keycloak.logout({ redirectUri: websiteURL });
        },
        reject: () => {},
      });
    },
  },
  data() {
    return {
      registeredUser: {},
    };
  },
};
</script>
