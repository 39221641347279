<template>
  <div class="change-profile">
    <div class="border-round list-left">
      <div class="surface-card card-border border-round surface-border mb-4">
        <div class="change-profile-card">
          <div class="copy-id">
            <h1 class="font-l" style="width: 100%">Je persoonlijke ID</h1>
            <div style="text-align: right">
              <Button
                label="Copy"
                icon="pi pi-copy"
                class="p-button"
                @click="copy"
              ></Button>
            </div>
          </div>
          <div class="card flex justify-content-center mt-4">
            <div class="flex flex-column gap-2" style="width: 100%">
              <div class="font-m">
                <input type="hidden" id="testing-code" :value="personID" />
                <InputText
                  disabled
                  id="Username"
                  v-model="personID"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="surface-card card-border border-round surface-border mb-4">
        <div class="change-profile-card">
          <div class="change-name">
            <h1 class="font-l mb-2" style="width: 100%">Je naam</h1>
            <div style="text-align: right">
              <Button
                label="Save"
                icon="pi pi-save"
                class="p-button"
                @click="registerUser()"
              ></Button>
            </div>
          </div>
          <div class="card flex justify-content-center">
            <div class="flex flex-column font-m gap-2" style="width: 100%">
              <label for="firstname">Voornaam*</label>
              <InputText
                id="firstname"
                v-model="firstName"
                @click="changebordercolorfname()"
              />
            </div>
          </div>
          <!-- <div class="card flex justify-content-center mt-4">
            <div class="flex flex-column font-m gap-2" style="width: 100%">
              <label for="middlename">Tussenvoegsel</label>
              <InputText id="middlename" v-model="middleName" />
            </div>
          </div>
          <div class="card flex justify-content-center mt-4">
            <div class="flex flex-column font-m gap-2" style="width: 100%">
              <label for="lastname">Achternaam*</label>
              <InputText
                id="lastname"
                v-model="lastName"
                @click="changebordercolorlname()"
              />
            </div>
          </div> -->
          <div class="text-900 w-full md:flex-order-0 flex-order-1 mt-4">
            <InlineMessage severity="error" id="error" style="display: none"
              >Vul alle verplichte velden in!</InlineMessage
            >
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="border-round list-right">
      <div class="surface-card card-border border-round surface-border">
        <div class="change-profile-card">
          <h1 class="font-l mb-4">Jou profielfoto</h1>
          <div class="change-profile-image align-items-center">
            <div class="profilepic mr-4">
              <Image
                v-for="photo in photos"
                :src="photo"
                alt="Image"
                width="100"
                preview
              />
            </div>
            <div class="upload-button">
              <h3 class="mb-4 font-m">Upload je nieuwe profielfoto</h3>
              <FileUpload
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                :maxFileSize="1000000"
                @upload="onUpload"
                :auto="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { fetchData } from "@/fetch";
export default {
  name: "ChangeProfileForm",
  mounted() {
    this.registeredUser = fetchData;
    this.addPhoto();
  },
  methods: {
    addPhoto() {
      if (this.registeredUser.photos) {
        this.photos.push(this.registeredUser.photos);
      }
    },
  },
  data() {
    return {
      registeredUser: {},
      value: null,
      photos: [],
    };
  },
};
</script>

<script setup>
import { useToast } from "primevue/usetoast";
import { ref, onMounted } from "vue";
import keycloak from "@/main";

const serverURL = "https://server.eetjethuis.nl";

const fetchData = ref(null);

const toast = useToast();

const show = (err) => {
  if (!err) {
    toast.add({
      severity: "success",
      summary: "Gekopieerd",
      life: 3000,
    });
  } else {
    toast.add({
      severity: "error",
      summary: "ID kon niet gekopieerd worden",
      life: 3000,
    });
  }
};

onMounted(() => {
  fetch(`${serverURL}/server/users/me`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.idToken,
    },
  })
    .then((response) => response.json())
    .then(
      (data) => (
        (fetchData.value = data),
        (personID.value = data.id),
        (firstName.value = data.name)
      )
    );
  return { fetchData };
});

function copy() {
  let testingCodeToCopy = document.querySelector("#testing-code");
  testingCodeToCopy.setAttribute("type", "text");
  testingCodeToCopy.select();

  try {
    document.execCommand("copy");
    show();
  } catch (err) {
    show(err);
  }

  /* unselect the range */
  testingCodeToCopy.setAttribute("type", "hidden");
  window.getSelection().removeAllRanges();
}

const firstName = ref(null);
const middleName = ref(null);
const lastName = ref(null);
const phone = ref(null);
const email = ref(null);
const birthDate = ref(null);
const personID = ref(null);

function registerUser() {
  var errors = false;
  if (firstName.value == null || firstName.value == "") {
    document.getElementById("firstname").style.border = "1px solid red";
    document.getElementById("error").style.display = "block";
    errors = true;
  }
  // if (lastName.value == null || lastName.value == "") {
  //   document.getElementById("lastname").style.border = "1px solid red";
  //   document.getElementById("error").style.display = "block";
  //   errors = true;
  // }
  if (errors) {
    // Get Login data when forms are filled in:
  } else {
    fetch(`${serverURL}/server/users/command/change-user-name`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.idToken,
      },
      body: JSON.stringify({ name: firstName.value }),
    }).then(() => location.reload());
  }
}

function changebordercolorfname() {
  document.getElementById("firstname").style.border = "1px solid #ced4da";
  document.getElementById("error").style.display = "none";
}

function changebordercolorlname() {
  document.getElementById("lastname").style.border = "1px solid #ced4da";
  document.getElementById("error").style.display = "none";
}

const onUpload = () => {
  toast.add({
    severity: "info",
    summary: "Success",
    detail: "File Uploaded",
    life: 3000,
  });
};

// const photos = ["https://scottpols.nl/assets/img/Scott1.jpg"];
</script>

<style scoped>
/* ----------------
 ChangeProfile Styling
-----------------*/
.change-profile-image,
.copy-id,
.change-name {
  display: flex;
}

.change-profile {
  height: auto;
  padding-bottom: 2vh;
  padding-top: 5vh;
}

.change-profile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 0px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1120px;
}

.list-left {
  grid-area: 1 / 1 / 2 / 2;
}
.list-right {
  grid-area: 1 / 2 / 3 / 3;
}

.change-profile-card {
  position: relative;
  height: auto;
  text-decoration: none;
  padding: 2rem;
  padding-bottom: 3rem;
}

.content-inblock-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 1000px) {
  .change-profile {
    display: block;
    padding-top: 1vh;
  }
  .surface-card {
    margin-bottom: 10px;
  }
}

@media (max-width: 475px) {
  .change-profile-image {
    display: block;
  }
  .upload-button {
    margin-top: 1.5rem;
  }
}
</style>

<style>
.upload-button .p-button,
.copy-id .p-button,
.change-name .p-button {
  background-color: transparent;
  color: var(--ejtblue);
  border: 1px solid var(--ejtblue);
  padding: 0.3rem 1rem;
}

.change-profile-image .p-image-preview-indicator {
  height: 100px;
}
</style>

<style scoped>
.profilepic {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--avatarcolor);
}
</style>
