<template>
  <div class="parent-div mt-4 mb-4">
    <div class="child1">
      <div class="toolbar card-border surface-card float-left">
        <div class="weekselector justify-content-center align-items-center">
          <Button
            severity="secondary"
            rounded
            @click="changeWeekIndex('prevWeek')"
            title="Vorige"
            id="prevWeek"
            :disabled="isDisabled"
            icon="pi pi-angle-double-left"
          >
          </Button>
          <h1 class="weekselectorcontent user-select-n font-m">
            Week {{ weekNumber }}
          </h1>
          <Button
            severity="secondary"
            rounded
            @click="changeWeekIndex('nextWeek')"
            title="Volgende"
            id="nextWeek"
            :disabled="isDisabled1"
            icon="pi pi-angle-double-right"
          >
          </Button>
        </div>
      </div>
    </div>
    <div class="child2">
      <div class="toolbar-extra-opties card-border surface-card float-right">
        <div class="flex gap-2 justify-content-center">
          <Button
            class="width100"
            label="Gehele week aanmelden"
            severity="success"
            rounded
            @click="confirm1(locatieOverzicht.meals, weekIndex, locationID)"
          />
          <Button
            class="p-button-sm"
            style="width: 50px; height: 50px"
            severity="info"
            title="Herladen"
            rounded
            icon="pi pi-refresh"
            @click="refreshData('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
  <section class="card1">
    <div class="grid">
      <template
        v-for="(dates, index) in locatieOverzicht.meals"
        :key="dates.date"
      >
        <div
          class="col-7"
          style="display: flex; flex-direction: column"
          v-if="index >= weekIndex * 7 && index <= weekIndex * 7 + 6"
        >
          <div
            class="surface-card p-3 user-select-n mb-3 flex card-border justify-content-between"
            style="border-radius: 3rem"
          >
            <span
              class="font-m ml-2"
              style="line-height: 32px"
              :class="{ dateToday: formattedToday === dates.date }"
            >
              {{ weekday[index].day }} - {{ dateTime(dates.date) }}</span
            >
            <div class="flex" style="justify-content: flex-end">
              <!-- <div>
                <Button
                  icon="pi pi-window-maximize"
                  text
                  rounded
                  style="width: 2rem; height: 2rem"
                  @click="(visible = true), (this.dateClicked = dates.date)"
                  title="Uitklappen"
                />
              </div> -->
              <Button
                icon="pi pi-minus-circle"
                severity="danger"
                text
                rounded
                style="width: 2rem; height: 2rem"
                title="Afmelden"
                @click="signOut(dates.date, index)"
                v-if="isUserRegistered(dates, registeredUser)"
              />
              <Button
                icon="pi pi-plus-circle"
                text
                rounded
                severity="success"
                style="width: 2rem; height: 2rem"
                @click="registerForMeal1(dates.date)"
                title="Aanmelden"
                v-if="!isUserRegistered(dates, registeredUser)"
              />
            </div>
          </div>
          <DayOverview
            @refresh="refreshData()"
            v-bind:dates="dates"
            v-bind:index="index"
            v-bind:show="show"
            v-bind:formattedToday="formattedToday"
            v-bind:locationID="locationID"
            v-bind:weekIndex="weekIndex"
            v-bind:locationName="locationName"
            v-bind:locatieOverzicht="locatieOverzicht"
            v-bind:registeredUser="registeredUser"
            v-bind:tabClicked="tabClicked"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import DayOverview from "./DayOverview.vue";
import { ref } from "vue";
import { fetchData, getLocatieOverzichtdata } from "@/fetch";
import { serverURL, baseURL } from "@/fetch";
import keycloak from "@/main";
import moment from "moment";

export default {
  name: "PersonOverview",
  components: { DayOverview },
  props: [
    "locationID",
    "locationName",
    "weekIndex",
    "weekNumber",
    "isDisabled",
    "isDisabled1",
    "formattedToday",
    "tabClicked",
  ],
  emits: ["changeWeekIndex"],
  inject: ["keycloak"],
  mounted() {
    this.loadLocationData();
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    async loadLocationData() {
      const LocatieData = ref(await getLocatieOverzichtdata(this.locationID));
      this.locatieOverzicht = LocatieData;
      this.registeredUser = fetchData;
    },
    isUserRegistered(dates, registeredUser) {
      for (let i = 0; i < dates.registrations.length; i++) {
        if (dates.registrations[i].id == registeredUser.id) {
          return true;
        }
      }
      return false;
    },
    async refreshData(data) {
      const LocatieData = ref(await getLocatieOverzichtdata(this.locationID));
      this.locatieOverzicht = LocatieData;
      this.registerDays = [];
      if (data === "refresh") {
        this.$toast.add({
          severity: "success",
          summary: "Ververst!",
          detail: "De gegevens zijn ververst",
          life: 3000,
        });
      }
    },
    changeWeekIndex(data) {
      this.$emit("changeWeekIndex", data);
    },
    confirm1(dates, weekIndex, locationID) {
      this.$confirm.require({
        group: "headless",
        message: "Wil je jezelf voor de gehele week aanmelden?",
        header: "Week aanmelden",
        accept: () => {
          this.registerForWeek(dates, weekIndex, locationID);
        },
        reject: () => {},
      });
    },
    async registerForWeek(dates, weekIndex, locationID) {
      dates.forEach((element) => {
        this.registerDays.push(element.date);
      });
      this.error = false;
      for (let i = 0; i < 7; i++) {
        await this.registerForMeal(
          this.registerDays[i + weekIndex * 7],
          locationID
        );
      }
      if (this.error === false) {
        this.$toast.add({
          severity: "success",
          summary: "Geslaagd",
          detail: "U bent succesvol voor de gehele week aangemeld!",
          life: 3000,
        });
      }
      this.refreshData();
    },
    async registerForMeal(date, locationID) {
      const seccondURL = locationID;
      await fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/register-for-meal`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            date: date,
            mealType: "DINNER",
          }),
        }
      ).then(
        function (response) {
          if (response.ok !== true && response.status !== 409) {
            this.error = true;
            this.$toast.add({
              severity: "error",
              summary: "Er is een fout opgetreden.",
              detail: "Probeer het opnieuw!",
              life: 3000,
            });
          } else if (response.ok !== true && response.status == 409) {
            this.error = true;
            this.$toast.add({
              severity: "error",
              summary: "Er is een fout opgetreden.",
              detail:
                "Je hebt al een aanmelding bij een andere locatie op " +
                this.dateTime(date),
              life: 3000,
            });
          }
        }.bind(this)
      );
    },
    signOut(date, index) {
      this.$confirm.require({
        group: "headless",
        message: "Weet u zeker dat u uzelf wilt afmelden voor deze datum?",
        header: "Afmelden?",
        // image: "/img/despicable-me-agnes.gif",
        accept: () => {
          let self = this;

          const seccondURL = self.locationID;
          fetch(
            `${serverURL}/${baseURL}/${seccondURL}/command/unregister-for-meal`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + keycloak.idToken,
              },
              body: JSON.stringify({
                date: date,
                mealType: "DINNER",
              }),
            }
          )
            .then(() => (this.refreshData(), (this.show[index] = null)))
            .then(() =>
              this.$toast.add({
                severity: "success",
                summary: "Afgemeld!",
                detail: "U bent afgemeld voor deze datum!",
                life: 3000,
              })
            );
        },
      });
    },
    async registerForMeal1(date) {
      let self = this;

      const seccondURL = self.locationID;
      await fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/register-for-meal`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            date: date,
            mealType: "DINNER",
          }),
        }
      )
        .then(
          function (response) {
            if (response.ok !== true && response.status !== 409) {
              this.error = true;
              this.$toast.add({
                severity: "error",
                summary: "Er is een fout opgetreden.",
                detail: "Probeer het opnieuw!",
                life: 3000,
              });
            } else if (response.ok !== true && response.status == 409) {
              this.error = true;
              this.$toast.add({
                severity: "error",
                summary: "Er is een fout opgetreden.",
                detail:
                  "Je hebt al een aanmelding bij een andere locatie op " +
                  this.dateTime(date),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Toegevoegd!",
                detail: "U bent succesvol aangemeld!",
                life: 3000,
              });
            }
          }.bind(this)
        )
        .then(() => this.refreshData());
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
  data() {
    return {
      visible: false,
      dateClicked: {},
      locatieOverzicht: {},
      registeredUser: {},
      registerDays: [],
      error: false,
      show: [null],
      weekday: [
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
      ],
    };
  },
};
</script>

<style scoped>
.mh-130 {
  position: relative;
}

@media (max-width: 767px) {
  .col-12 {
    padding: 0.5rem 0;
  }
}

.divider-class {
  margin: 0 !important;
  bottom: 1rem;
}

.parent-div {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  vertical-align: middle;
}

@media (max-width: 644px) {
  .parent-div {
    grid-template-columns: 1.4fr 2fr;
  }
}

@media (max-width: 555px) {
  .child1 {
    grid-area: 1 / 1 / 2 / 2 !important;
  }
  .child2 {
    grid-area: 2 / 1 / 3 / 2 !important;
  }
  .parent-div {
    grid-template-columns: 2fr;
  }
  .float-right {
    float: none !important;
  }

  .float-left {
    float: none !important;
  }
}

.child1 {
  grid-area: 1 / 1 / 2 / 2;
}
.child2 {
  grid-area: 1 / 2 / 2 / 3;
}

.toolbar {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-radius: 6px;
}
.toolbar {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3rem;
  padding: 0.5rem;
}

.toolbar-extra-opties {
  display: grid;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-radius: 6px;
}
.toolbar-extra-opties {
  border-radius: 3rem;
  padding: 0.5rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}
</style>

<style>
.p-toast-detail {
  color: var(--ejtblack);
}
</style>
